<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Generate Normalisasi
        <div class="card-header-actions">
          <a href="" class="card-header-action" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <b-form novalidate>
              <b-form-group id="emailInput" label="User" label-for="email">
                <b-form-input
                  id="email"
                  type="text"
                  v-model.lazy.trim="$v.form.email.$model"
                  :state="chkState('email')"
                  aria-describedby="emailFeedback"
                  placeholder="Input Email User"
                  autofocus
                />
                <b-form-invalid-feedback id="emailFeedback">
                  <span v-if="!$v.form.email.required">
                    - Email user tidak boleh kosong
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="type" label="Tipe Transaksi" label-for="type">
                <b-form-select
                  id="type"
                  :plain="true"
                  v-model.lazy.trim="$v.form.type.$model"
                  :state="chkState('type')"
                  aria-describedby="typeFeedback"
                >
                  <option value=""
                    >Pilih Tipe Transaksi (Mengurangi atau Menambah
                    Saldo)</option
                  >
                  <option
                    v-for="(type, key) in types"
                    :key="key"
                    :value="type.id"
                  >
                    {{ type.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback id="typeFeedback">
                  <span v-if="!$v.form.type.required">
                    - Tipe Transaksi Tidak Boleh Kosong
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="amount" label="Gold Amount" label-for="amount">
                <b-form-input
                  id="amount"
                  type="number"
                  v-model.trim="$v.form.amount.$model"
                  :state="chkState('amount')"
                  aria-describedby="amountFeedback"
                  placeholder="Input Jumlah Gramasi Emas"
                  autocomplete="amount"
                />
                <b-form-invalid-feedback id="amountFeedback">
                  <span v-if="!$v.form.amount.required">
                    - Gold Amount Tidak Boleh Kosong
                  </span>
                  <span v-if="!$v.form.amount.decimal">
                    Gold Amount Harus Berupa Angka Valid
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="date"
                label="Waktu Transaksi (Pilih Waktu Untuk Menentukan Harga Emas Yang Digunakan)"
                label-for="date"
              >
                <b-input-group aria-describedby="dateFeedback">
                  <date-picker
                    id="date"
                    v-model.trim="$v.form.date.$model"
                    format="YYYY-MM-DD HH:mm"
                    type="datetime"
                    lang="en"
                    aria-describedby="dateFeedback"
                    value-type="format"
                    aria-placeholder="YYYY-MM-DD HH:mm"
                    required
                  ></date-picker>
                </b-input-group>
                <div v-if="this.form.date == ''" style="color: #f86c6b;">
                  <span v-if="!$v.form.date.required">
                    - Waktu Transaksi Tidak Boleh Kosong
                  </span>
                </div>
              </b-form-group>
              <b-form-group
                id="notification"
                label="Push Notification - Title"
                label-for="notification"
              >
                <b-form-input
                  id="notification"
                  type="text"
                  v-model.lazy.trim="$v.form.notification.$model"
                  :state="chkState('notification')"
                  aria-describedby="notificationFeedback"
                  placeholder="Input Copy Text Push Notification"
                  autofocus
                />
                <b-form-invalid-feedback id="notificationFeedback">
                  <span v-if="!$v.form.notification.required">
                    - Copy Text Untuk Push Notification Tidak Boleh Kosong
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="notificationBody"
                label="Push Notification - Body"
                label-for="notificationBody"
              >
                <b-form-input
                  id="notificationBody"
                  type="text"
                  v-model.lazy.trim="$v.form.notification_body.$model"
                  :state="chkState('notification_body')"
                  aria-describedby="notificationBodyFeedback"
                  placeholder="Input Body Push Notification"
                  autofocus
                />
                <b-form-invalid-feedback id="notificationBodyFeedback">
                  <span v-if="!$v.form.notification_body.required">
                    - Body Untuk Push Notification Tidak Boleh Kosong
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="description"
                label="Description - Copy Text"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  type="text"
                  v-model.lazy.trim="$v.form.description.$model"
                  :state="chkState('description')"
                  aria-describedby="descriptionFeedback"
                  placeholder="Input Copy Text Description Internal Dashboard"
                  autofocus
                />
                <b-form-invalid-feedback>
                  <span v-if="!$v.form.description.required">
                    - Copy Text Untuk Description Tidak Boleh Kosong
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group class="my-4" id="checkbos">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model.lazy.trim="form.ichSend"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
                >
                  ICH Send
                </b-form-checkbox>
              </b-form-group>
              <b-button
                type="button"
                variant="primary"
                @click="onSubmit"
                :disabled="this.isBusy"
              >
                <b-spinner small v-if="this.isBusy"></b-spinner>
                <span v-if="!this.isBusy">Submit</span>
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";

const decimal = helpers.regex(
  "decimal",
  /^[+]?([0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?$/
);

export default {
  name: "Create",
  data() {
    return {
      form: {
        email: "",
        type: "",
        amount: "",
        date: moment().format("YYYY-MM-DD HH:mm"),
        notification: "",
        notification_body: "",
        description: "",
        ichSend: false
      },
      //isDate: moment().format('YYYY-MM-DD HH:mm'),
      types: [
        { id: "cr", name: "Tambah Saldo" },
        { id: "db", name: "Kurangi Saldo" }
      ],
      isLoading: false,
      isBusy: false,
      errors: {
        code: "",
        message: "",
        status: ""
      }
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required
      },
      type: {
        required
      },
      amount: {
        required,
        decimal
      },
      date: {
        required
      },
      notification: {
        required
      },
      notification_body: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }

      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });
      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    onSubmit() {
      if (this.validate()) {
        this.isBusy = true;
        this.$swal
          .fire({
            title: "Confirmation",
            text: `Apakah Kamu Yakin Untuk ${
              this.form.type === "cr" ? "Tambah Saldo" : "Kurangi Saldo"
            } - ${this.form.email} - ${this.form.amount} gram?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Batal"
          })
          .then(result => {
            if (result.value) {
              const payload = {
                transaction_date: this.form.date,
                email: this.form.email,
                amount: this.form.amount,
                type: this.form.type,
                transaction_type: 22,
                description: this.form.description,
                notification_title: this.form.notification,
                notification_body: this.form.notification_body,
                ich_send: this.form.ichSend
              };
              this.$http
                .post(`inject/normalisasi`, payload)
                .then(() => {
                  this.$swal
                    .fire(
                      "Success!",
                      "Normalization transaction successfully",
                      "success"
                    )
                    .then(() => {
                      location.reload();
                      this.isBusy = false;
                    });
                })
                .catch(error => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                    this.$swal.fire(
                      "Failed",
                      error.response.data.meta.message,
                      "error"
                    );
                    this.isBusy = false;
                  }
                });
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelled",
                "Account was successfully canceled for Normalization Transaction!",
                "error"
              );
            }
          });
      }
    }
  }
};
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>
